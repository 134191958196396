<template>
  <main role="main">
    <meta-title :title="metaTitle"></meta-title>
    <div class="head">
        <p>{{ $t("tenYears.head_title") }}</p>
        <img src="@/assets/img/10-years/plane.png" alt="" />
    </div>
    <section class="section">
      <div class="container">
        <div class="company-head size pd-tp">
          <h2>
            {{ $t("tenYears.title1") }}
          </h2>
        </div>
        <div class="year-cards">
          <picture>
            <source
              srcset="@/assets/img/10-years/peoples-ms.jpg"
              media="(max-width: 413px)"
            />
            <source
              srcset="@/assets/img/10-years/peoples.jpg"
              media="(max-width: 767px)"
            />
            <source
              srcset="@/assets/img/10-years/peoples-s.jpg"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/img/10-years/peoples-m.jpg"
              media="(max-width: 1439px)"
            />
            <img src="@/assets/img/10-years/peoples.jpg" alt="" />
          </picture>

          <picture>
            <source
              srcset="@/assets/img/10-years/girls-ms.jpg"
              media="(max-width: 413px)"
            />
            <source
              srcset="@/assets/img/10-years/girls.jpg"
              media="(max-width: 767px)"
            />
            <source
              srcset="@/assets/img/10-years/girls-s.jpg"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/img/10-years/girls-m.jpg"
              media="(max-width: 1439px)"
            />
            <img src="@/assets/img/10-years/girls.jpg" alt="" />
          </picture>
        </div>

        <div class="company-head size ta-center">
          <h2>{{ $t("tenYears.title2") }}</h2>
        </div>

        <div class="year-awards">
          <figure>
            <picture>
              <source
                srcset="@/assets/img/10-years/awards-1-s.png"
                media="(max-width: 1279px)"
              />
              <img src="@/assets/img/10-years/awards-1.png" alt="" />
            </picture>
            <figcaption v-html="$t('tenYears.tile1')"></figcaption>
          </figure>

          <figure>
            <picture>
              <source
                srcset="@/assets/img/10-years/awards-2-s.png"
                media="(max-width: 1279px)"
              />
              <img src="@/assets/img/10-years/awards-2.png" alt="" />
            </picture>
            <figcaption v-html="$t('tenYears.tile2')"></figcaption>
          </figure>

          <figure>
            <picture>
              <source
                srcset="@/assets/img/10-years/awards-3-s.png"
                media="(max-width: 1279px)"
              />
              <img src="@/assets/img/10-years/awards-3.png" alt="" />
            </picture>
            <figcaption v-html="$t('tenYears.tile3')"></figcaption>
          </figure>
        </div>

        <div class="year-work">
          <picture>
            <source
              srcset="@/assets/img/10-years/work-s.jpg"
              media="(max-width: 413px)"
            />
            <source
              srcset="@/assets/img/10-years/work-m.jpg"
              media="(max-width: 767px)"
            />
            <source
              srcset="@/assets/img/10-years/work.jpg"
              media="(max-width: 1023px)"
            />
            <source
              srcset="@/assets/img/10-years/work-m.jpg"
              media="(max-width: 1279px)"
            />
            <img src="@/assets/img/10-years/work.jpg" alt="" />
          </picture>

          <div class="year-work__description">
            <p class="description-text">
              {{ $t("tenYears.text1") }}
            </p>
            <p class="description-text" v-html="$t('tenYears.text2')"></p>
          </div>
        </div>

        <div
          class="company-head size ta-center"
          v-html="$t('tenYears.title3')"
        ></div>

        <div class="year-fakt">
          <figcaption v-html="$t('tenYears.tile4')"></figcaption>
          <figcaption v-html="$t('tenYears.tile5')"></figcaption>
        </div>

        <div class="company-head size ta-center">
          <h2>{{ $t("tenYears.title4") }}</h2>
        </div>

        <div class="year-fakt bg-pink">
          <figcaption v-html="$t('tenYears.tile6')"></figcaption>
          <figcaption v-html="$t('tenYears.tile7')"></figcaption>
          <figcaption v-html="$t('tenYears.tile8')"></figcaption>
        </div>

        <div class="company-head size ta-center">
          <h2>{{ $t("tenYears.title5") }}</h2>
        </div>

        <div class="year-values">
          <div class="co-cards">
            <ul>
              <h3>{{ $t("tenYears.tile9_title") }}</h3>
              <li>
                <span>{{ $t("tenYears.tile9_1") }} </span>
              </li>
              <li>
                <span>{{ $t("tenYears.tile9_2") }} </span>
              </li>
              <li>
                <span>{{ $t("tenYears.tile9_3") }} </span>
              </li>
            </ul>
          </div>
          <div class="co-cards">
            <ul>
              <h3>{{ $t("tenYears.tile10_title") }}</h3>
              <li>
                <span>
                  {{ $t("tenYears.tile10_1") }}
                </span>
              </li>
              <li>
                <span>
                  {{ $t("tenYears.tile10_2") }}
                </span>
              </li>
              <li>
                <span>
                  {{ $t("tenYears.tile10_3") }}
                </span>
              </li>
            </ul>
          </div>
          <div class="ompany-co__on-bort co-cards">
            <ul>
              <h3>{{ $t("tenYears.tile11_title") }}</h3>
              <li>
                <span>{{ $t("tenYears.tile11_1") }} </span>
              </li>
              <li>
                <span>{{ $t("tenYears.tile11_2") }} </span>
              </li>
              <li>
                <span>{{ $t("tenYears.tile11_3") }} </span>
              </li>
            </ul>
          </div>
        </div>

        <div class="year-thanks">
          <h3>{{ $t("tenYears.footer") }}</h3>
          <img src="@/assets/img/10-years/love.png" alt="" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import cover from "@/mixins/cover";

export default {
  name: "TenYears",
  title() {
    return this.$t("pages.tenYears");
  },
  mixins: [cover],
  data: () => {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {}
};
</script>

<style scoped></style>
